@import "../../assets/style/variables";

@keyframes popup {
  0% {
    bottom: -50px;
    background: rgba(0, 0 , 0, 0.3);
  }
  100% {
    bottom: 50px;
    background: rgba(0, 0 , 0, 0.9);
  }
}
@keyframes popdown {
  0% {
    bottom: 50px;
    background: rgba(0, 0 , 0, 0.9);
  }
  100% {
    bottom: -50px;
    background: rgba(0, 0 , 0, 0.3);
  }
}

.page-sub-tab-wrapper {
  .tab-item {
    border: none;
    cursor: pointer;
    border-radius: 6px;
    color: #646464;
    .ant-card-body {
      background-color: #F8F8F8;
    }
    &.active, &:hover {
      .ant-card-body {
        background-color: @color-white;
      }
    }
  }
}

.theme-layout.dark {
  .tab-item {
    color: #F8F8F8;
    .ant-card-body {
      background-color: #333333;
    }
    &.active, &:hover {
      .ant-card-body {
        background-color: #414141;
      }
    }
  }
}

.ubot-toast {
  position: fixed;
  left: 174px;
  bottom: -50px;
  background: rgba(0, 0, 0, 0.3);
  padding: 12px;
  border-radius: 4px;
  z-index: 99999;
  display: flex;
  align-items: center;
  cursor: pointer;
  &.show {
    animation-duration: 0.3s;
    animation-name: popup;
    bottom: 50px;
    background: rgba(0, 0, 0, 0.9);
  }
  .toast-icon {
    font-size: 34px;
  }
  .content {
    margin-left: 12px;
    color: @color-white;
  }
  @media (max-width: 991px) {
    left: 104px;
    &.show {
      bottom: 24px;
    }
  }
  @media (max-width: 768px) {
    left: 12px;
    max-width: calc(100% - 24px);
  }
}
@primary-color: #1D99F1;@link-color: #17C2D7;