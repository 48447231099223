.loading-gif-wrapper {
    width: 70px;
    height: 70px;
    margin: auto;
    img {
        width: 100%;
        height: 100%;
    }
}

.theme-loader-wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.table-loader {
    .ant-skeleton-content {
        vertical-align: middle;
        .ant-skeleton-paragraph {
            margin-bottom: 0 !important;
        }
    }
}
@primary-color: #1D99F1;@link-color: #17C2D7;